<template>
  <div class="wrapper pageBox">
    <div class="banner">
      <img src="https://zhongjia-data.oss-cn-hangzhou.aliyuncs.com/web/assets/img/banner.png">
      <div class="bannerText">
        <div class="title">中嘉未来视野</div>
        <div class="btn">了解中嘉数科更多资讯</div>
      </div>
    </div>
    <div class="conBox">
      <div v-if="notEmpty(monitor)" class="tabs">
        <div v-for="(item, index) in navIds" :key="index" class="li" @click="go(index, item)">
          <span class="text" :class="{'selected': lastIndex === index}">{{ item.name }}</span>
        </div>
      </div>
      <div class="search">
        <el-input v-model="queryParams.title" clearable placeholder="请输入关键词" />
        <i class="el-icon-search" @click="search" />
      </div>
      <div class="pushLabel">
        <div class="label">推荐标签：</div>
        <div
          v-for="(item, index) in labelList"
          :key="index"
          class="value"
          :class="queryParams.label === item ? 'selected' : ''"
          @click="clickLabel(item)"
        >{{ item }}</div>
      </div>
      <div class="listBox">
        <div v-for="(item, index) in tableData" :key="index" class="li">
          <div class="left">
            <div class="date">{{ item.createDate ? item.createDate.substring(5, 10) : '' }}</div>
            <div class="rowLine" />
            <div class="year">{{ item.createDate ? item.createDate.substring(0, 4) : '' }}</div>
          </div>
          <div class="colLine" />
          <div class="right">
            <div class="title" @click="goInfo(item)">{{ item.title }}</div>
            <div class="text">{{ item.desction }}</div>
            <div v-if="item.label" class="labels">
              <template v-for="(value, key) in item.label.split(' ')">
                <div v-if="notEmpty(value)" :key="key" class="label">
                  <span>{{ value }}</span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="page">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          :page-sizes="[5, 10, 20, 40]"
          :page-size="queryParams.limit"
          :current-page="queryParams.page"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
// 接口
import { getNewsList } from '@/service/api';
export default {
  name: 'News',
  data() {
    return {
      // 查询参数
      queryParams: {
        page: 1, // 当前页
        limit: 8, // 每页显示条数
        navIds: [], // 通过id区分不同tab
        title: '', // 搜索文章关键词
        label: '' // 推荐标签搜索文章
      },
      tableData: [],
      lastIndex: 0,
      labelList: ['数字园区', '中标公告', '十四五'],
      navIds: [{ name: '全部' }],
      total: 0,
      timer: null
    };
  },
  computed: {
    monitor() {
      return this.$store.state.navIds;
    }
  },
  watch: {
    '$route.query.id': {
      immediate: false,
      handler() {
        if (this.$route.query.type !== '0') {
          let obj = null;
          this.navIds.forEach((item, index) => { if (Number(this.$route.query.type) === index) obj = item; });
          this.go(Number(this.$route.query.type), obj);
          return;
        }
      }
    },
    monitor: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val !== null) {
          this.navIds = [...this.navIds, ...val.child];
          if (this.$route.query.type !== '0') {
            let obj = null;
            this.navIds.forEach((item, index) => { if (Number(this.$route.query.type) === index) obj = item; });
            this.go(Number(this.$route.query.type), obj);
            return;
          }
          val.child.forEach((item, index) => {
            this.queryParams.navIds.push(item.id);
          });
          this.lastIndex = 0;
          this.getData();
        }
      }
    }
  },
  mounted() {

  },
  methods: {
    async getData() {
      const res = await getNewsList(this.queryParams);
      if (res.code === 200) {
        this.tableData = res.page.list;
        this.total = res.page.totalCount;
      }
    },
    search() {
      this.queryParams.page = 1;
      this.getData();
    },
    clickLabel(item) {
      if (!this.notEmpty(this.queryParams.label) || this.queryParams.label !== item) {
        this.queryParams.label = item;
        this.search();
      } else {
        this.queryParams.label = '';
        this.search();
      }
    },
    go(index, item) {
      console.log('item.id', item.id);
      if (item.id) {
        this.queryParams.navIds = [item.id];
      } else {
        this.monitor.child.forEach((item, index) => {
          this.queryParams.navIds.push(item.id);
        });
      }
      this.lastIndex = index;
      this.queryParams.label = '';
      this.getData();
    },
    goInfo(item) {
      this.$router.push({
        path: '/news/info',
        query: {
          id: item.id,
          navId: item.navId
        }
      });
    },
    // 翻页
    handleCurrentChange(val) {
      this.queryParams.page = val;
      this.getData();
    }
  }
};
</script>
<style lang="scss" scoped>
.conBox {
  width: 1282px;
  margin: 0 auto;
  .tabs {
    padding-top: 43px;
  }
  .search {
    position: relative;
    width: 700px;
    margin: 0 auto;
    .el-input {
      border-radius: 26px;
      border: 2px solid #E7EAEF;
      overflow: hidden;
    }
    /deep/ .el-input__inner {
      padding-left: 42px;
      width: 620px;
      height: 48px;
      line-height: 48px;
      border: none;
      font-size: 18px;
      &::placeholder {
        color: #C5CBD3;
        font-size: 18px;
      }
    }
    /deep/ .el-input__clear {
      font-size: 30px;
      line-height: 46px;
      padding-right: 80px;
    }
    i {
      position: absolute;
      top: 10px;
      right: 44px;
      font-size: 30px;
      color: #DADEE4;
      cursor: pointer;
    }
  }
  .pushLabel {
    margin: 0 auto;
    margin-top: 24px;
    padding-left: 46px;
    width: 700px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #9299A5;
    font-size: 16px;
    .label {
      margin-right: 24px;
      font-size: 18px;
    }
    .value {
      margin-right: 30px;
      padding: 0 15px;
      height: 30px;
      line-height: 30px;
      background: rgba(138, 160, 197, .1);
      border-radius: 8px;
      cursor: pointer;
      &.selected {
        color: #144fd1;
      }
    }
  }
  .listBox {
    margin-top: 26px;
    .li {
      width: 100%;
      min-height: 177px;
      display: flex;
      border-bottom: 1px solid #DEE0E5;
      .rowLine {
        width: 54px;
        height: 1px;
        background: #D1D5DE;
      }
      .colLine {
        margin-top: 38px;
        width: 1px;
        height: 79px;
        background: #D1D5DE;
      }
      .left {
        margin-top: 26px;
        box-sizing: border-box;
        padding-left: 46px;
        width: 186px;
        .date {
          margin-bottom: 6px;
          font-size: 36px;
          color: #252B3A;
          font-weight: bold;
        }
        .year {
          margin-top: 10px;
          font-size: 18px;
          color: #B9BDC6;
          font-family: 'siyuan-medium' !important;
        }
      }
      .right {
        margin-top: 30px;
        margin-bottom: 20px;
        padding-left: 28px;
        flex: 1;
        .title {
          cursor: pointer;
          margin-bottom: 7px;
          font-size: 24px;
          color: #252B3A;
          font-family: 'siyuan-medium' !important;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          &:hover {
            color: #144fd1;
          }
        }
        .text {
          padding-right: 36px;
          font-size: 14px;
          color: #6A6E77;
          line-height: 24px;
          letter-spacing: .5px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        .labels {
          margin-top: 18px;
          display: flex;
          .label {
            margin-right: 16px;
            padding: 1px 8px;
            border: 1px solid #D1D5DE;
            color: #B9BDC6;
            font-size: 12px;
            border-radius: 3px;
          }
        }
      }
    }
  }
  .page {
    margin-top: 33px;
    margin-bottom: 42px;
  }
}
</style>
